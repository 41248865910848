.form-control,
.custom-select {
  &.form-control-border {
    background-color: transparent;
    border-top: 0;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    box-shadow: inherit;

    &.border-width-2 {
      border-bottom-width: 2px;
    }
    &.border-width-3 {
      border-bottom-width: 3px;
    }
    &.is-invalid {
      border-color: #dc3545;
    }
  }
}
.flip-container {
  perspective: 1000px;
}

.image {
  transition: transform 1s; /* Duration of the flip */
  transform-style: preserve-3d;
}

.flip .image {
  transform: rotateY(360deg);
}
.btn-whatsapp a {
  color: #fff;
  text-decoration: none;
  display: block;
}

.btn-whatsapp {
  position: fixed;
  left: 75px;
  bottom: 0px;
  transform: translate(-50%, -50%);
  background-color: rgb(37, 211, 102);
  width: 60px;
  height: 60px;
  text-align: center;
  line-height: 58px;
  font-size: 1.8em;
  color: #ffffff;
  font-weight: 100;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-whatsapp:before,
.btn-whatsapp:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  border: 1px solid #25d366;
  left: -20px;
  right: -20px;
  bottom: -20px;
  top: -20px;
  animation: animate 1.5s linear infinite;
  opacity: 0;
  backface-visibility: hidden;
}

.pulsaDelay:after {
  animation-delay: 0.5s;
}

:root {
  --bright-greek: rgb(58, 77, 251);
  --orange: rgb(216, 129, 6);
}
.greek {
  color: var(--bright-greek);
}
.font-20 {
  font-size: 1.25rem;
}
ul {
  list-style-type: none;
}
ul li:before {
  content: "•";
  font-size: 180%;
  padding-right: 5px;
}
.background-index {
  background-image: url(https://cdn.greatsoftwares.com.br/arquivos/paginas_editor/1-a40f34101a17306091d368fd8cf393f8.png);
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.circle {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bright-greek);
}
.p-index {
  background-color: var(--bright-greek);
  border-radius: 0px 0px 29px 29px;
}
.border-greek {
  border-color: var(--bright-greek) !important;
}

.container-celular {
  position: relative; /* Necessário para posicionar o círculo */
  display: flex; /* Flexbox para centralizar conteúdo (opcional) */
  justify-content: center;
  align-items: center;
}

.container-celular::before {
  content: "";
  position: absolute;
  width: 300px;
  height: 300px;
  background-color: var(--bright-greek);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-celular-circulo {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container-celular-circulo::before {
  content: "";
  position: absolute;
  width: 500px;
  height: 500px;
  background-color: transparent;
  border: 1px solid var(--bright-greek);
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 0;
}
.bg-orange {
  background-color: var(--orange) !important;
  border-color: var(--orange) !important;
  font-size: 1.5rem;
  height: 4.509rem;
}
